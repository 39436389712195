<template>
  <div>
        <v-btn @click="newKtSet">NewKtSet</v-btn>
        <v-container>
            <v-row>
                <v-col>
                    <v-list>
                        <v-list-item v-for="(Set, Key ) in ktSet" :key="`ktSet-${Key}`">
                            <v-list-item-content>
                                {{ Key }}
                            </v-list-item-content>
                            <v-list-item-action>
                                <div>
                                    <v-btn icon small color="primary" @click="copy(Set)">
                                        <v-icon>mdi-content-copy</v-icon>
                                    </v-btn>
                                    <v-btn icon small @click="editKtSet(Key, Set)">
                                        <v-icon small>
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                    <v-btn icon small color="warning" @click="removeKtSet(Key)">
                                        <v-icon small>
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col>
                    <json-viewer :value="editing"/>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-model="editingUI" :persistent="true" :fullscreen="true">
            <v-card v-if="editing.ktSet">
                <v-card-actions>
                    <v-text-field v-model="editing.ktSetId" label="ktSetId"/>
                </v-card-actions>

                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="4">
                                <v-tabs v-model="tab">
                                    <v-tab href="#recommendations">
                                        Recommendations
                                    </v-tab>
                                    <v-tab href="#treatments">
                                        Treatments
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                        </v-row>
                        <v-tabs-items v-model="tab">
                            <v-tab-item data-test-tabItem="recommendations" :value="'recommendations'">
                                <v-row>
                                    <v-col cols="4">
                                        <h1>Recommendations</h1>    
                                        <v-btn @click="fixRecords(editing.ktSet.recommendations)">Fixer</v-btn>
                                        <v-sheet height="800" style="overflow: scroll;">
                                            <v-card v-for="(Recommendation, RecommendationId) in editing.ktSet.recommendations" :key="`recommendation-${RecommendationId}`">
                                                <v-card-actions>
                                                    [{{ RecommendationId }}]
                                                    <v-btn small icon @click="editRecommendation(RecommendationId, Recommendation)">
                                                        <v-icon>
                                                            mdi-pencil
                                                        </v-icon>
                                                    </v-btn>
                                                    <v-spacer></v-spacer>
                                                    <v-btn small icon @click="removeRecommendation(RecommendationId)">
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </v-card-actions>
                                                <v-card-text>
                                                    {{ Recommendation.body['en-ca'] }}
                                                </v-card-text>
                                            </v-card>
                                        </v-sheet>                            
                                        <hr>
                                        <v-btn fab color="success" style="position: fixed; left: 15px; bottom: 50px;" @click="newRecommendation()">+R</v-btn>
                                    </v-col>
                                    <v-col v-if="editing.recommendation">
                                        Recommendation
                                        <v-btn @click="newTreatment">New Treatment</v-btn>
                                        <v-container>
                                            <v-row>
                                                <v-text-field v-model="editing.recommendationId" label="Recommendation ID"></v-text-field>
                                            </v-row>
                                            <v-row>
                                                <v-container>
                                                    <v-row>
                                                        <v-col>
                                                            Triggers
                                                            <v-list>
                                                                <v-list-item v-for="(TriggerId, TriggerIndex) in editing.recommendation.triggers" :key="`trigger-${TriggerIndex}`">
                                                                    <v-list-item-content>
                                                                        {{ TriggerId }}
                                                                    </v-list-item-content>
                                                                    <v-list-action>
                                                                        <v-btn fab x-small @click="buffer.triggers.index=TriggerIndex; buffer.triggers.value=TriggerId">
                                                                            <v-icon>mdi-pencil</v-icon>
                                                                        </v-btn>
                                                                        <v-btn fab x-small @click="editing.recommendation.triggers.splice(TriggerIndex,1)">
                                                                            <v-icon>mdi-delete</v-icon>
                                                                        </v-btn>
                                                                    </v-list-action>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-col>
                                                        <v-col>
                                                            <v-text-field v-model="buffer.triggers.value" label="Trigger Logic ID"></v-text-field>
                                                            <v-btn v-if="buffer.triggers.index==null" @click="editing.recommendation.triggers.push(buffer.triggers.value);buffer.triggers.value=null" text>
                                                                Add
                                                            </v-btn>
                                                            <v-btn v-else @click="editing.recommendation.triggers[buffer.triggers.index]=buffer.triggers.value;buffer.triggers.index=null;buffer.triggers.value=null" text>
                                                                Update
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-row>
                                            <v-row>
                                                <v-col v-for="Language in Languages" :key="`recommendation-input-${Language.value}`">
                                                    <h2>{{ Language.text }}</h2>
                                                    <v-text-field v-model="editing.recommendation.body[Language.value]" label="Body"></v-text-field>
                                                    <v-container>
                                                        <v-row v-for="(Treatment, TreatmentIndex) in editing.recommendation.treatments" :key="`treatment-input-${TreatmentIndex}`">
                                                            <v-col>
                                                                <v-text-field v-model="Treatment.option[Language.value]" label="Option Text"/>
                                                            </v-col>
                                                            <v-col>
                                                                <v-text-field v-model="Treatment.kt_id" clearable label="KT ID"/>
                                                            </v-col>
                                                            <v-col>
                                                                <v-btn small icon @click="removeTreatment(TreatmentIndex)"><v-icon>mdi-delete</v-icon></v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                    <v-text-field v-model="editing.recommendation.footer[Language.value]" label="Footer"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-btn @click="saveRecommendation(editing.recommendationId, editing.recommendation)">Save Recommendation</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-col>
                                </v-row>                            
                            </v-tab-item>
                            <v-tab-item data-test-tabItem="treatments" :value="'treatments'">
                                <v-row>
                                    <v-col cols="3">
                                        <h1>Treatments</h1>    
                                        <v-sheet height="800" style="overflow: scroll;">
                                            <v-card v-for="(Treatment, TreatmentId) in editing.ktSet.kt.treatments" :key="`recommendation-${TreatmentId}`">
                                                <v-card-actions>
                                                    [{{ TreatmentId }}]
                                                    <v-btn small icon @click="editKtTreatment(TreatmentId, Treatment)">
                                                        <v-icon>
                                                            mdi-pencil
                                                        </v-icon>
                                                    </v-btn>
                                                    <v-spacer></v-spacer>
                                                    <v-btn small icon @click="removeKtTreatment(TreatmentId)">
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </v-card-actions>
                                                <v-card-title>
                                                    {{ Treatment.title['en-ca'] }}
                                                </v-card-title>
                                            </v-card>
                                        </v-sheet>
                                        <hr>
                                        <v-btn fab color="warning" style="position: fixed; left: 15px; bottom: 50px;" @click="newKtTreatment()">+T</v-btn>
                                    </v-col>
                                    <template v-if="editing.treatment">
                                        <v-col cols="3">
                                            <v-container fluid>
                                                <v-row>
                                                    Drugs
                                                    <v-col>
                                                        <v-list>
                                                            <v-list-item v-for="(Drug, DrugIndex) in editing.treatment.drugs" :key="`drugIndex-${DrugIndex}`">
                                                                <v-list-item-content>
                                                                    {{ Drug }}
                                                                </v-list-item-content>
                                                                <v-list-item-action>
                                                                    <v-btn @click="editKtField('drugs', DrugIndex, editing.treatment['drugs'])" x-small icon>
                                                                        <v-icon x-small>
                                                                            mdi-pencil
                                                                        </v-icon>
                                                                    </v-btn>
                                                                    <v-btn @click="removeKtField('drugs', DrugIndex, editing.treatment['drugs'])" x-small icon>
                                                                        <v-icon x-small>
                                                                            mdi-delete
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </v-list-item-action>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-for="Field in ['start','monitoring','qol','references']" :key="`array-${Field}`">
                                                    {{ Field }}
                                                    <v-col>
                                                        <v-list>
                                                            <v-list-item v-for="(Item, ItemIndex) in editing.treatment[Field]" :key="`item-${Field}-${ItemIndex}`">
                                                                <v-list-item-content>
                                                                    <v-list-item-title>
                                                                        [{{ItemIndex+1}}] - <span v-html="Item['en-ca']"/>
                                                                    </v-list-item-title>
                                                                    <v-list-item-subtitle>
                                                                        <span v-html="Item['fr-ca']"/>
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                                <v-list-item-action>
                                                                    <v-btn @click="editKtField(Field, ItemIndex, editing.treatment[Field])" x-small icon>
                                                                        <v-icon x-small>
                                                                            mdi-pencil
                                                                        </v-icon>
                                                                    </v-btn>
                                                                    <v-btn @click="removeKtField(Field, ItemIndex, editing.treatment[Field])" x-small icon>
                                                                        <v-icon x-small>
                                                                            mdi-delete
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </v-list-item-action>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-col>
                                        <v-col>
                                            Treatment
                                            <v-container>
                                                <v-row>
                                                    <v-text-field v-model="editing.treatmentId" label="Treatment ID"></v-text-field>
                                                </v-row>
                                                <v-textarea v-model="buffer.drugs.value" label="Drugs"></v-textarea>
                                                <v-btn text @click="saveItem('drugs', buffer.drugs.index, buffer.drugs.value, editing.treatment.drugs)">
                                                    {{buffer.drugs.index==null ? 'Add' : 'Update'}}
                                                </v-btn>
                                                <v-row>
                                                    <v-col v-for="Language in Languages" :key="`recommendation-input-${Language.value}`">
                                                        <h2>{{ Language.text }}</h2>
                                                        <v-text-field v-model="editing.treatment.title[Language.value]" label="Title"></v-text-field>
                                                        <v-card v-for="field in ['start','monitoring','qol','references']" :key="`input-${Language.value}-${field}`">
                                                            <v-card-text>
                                                                <v-textarea v-model="buffer[field].value[Language.value]" :label="field"/>
                                                            </v-card-text>
                                                            <v-card-actions>
                                                                <v-btn @click="saveItem(field, buffer[field].index, buffer[field].value, editing.treatment[field])">
                                                                    {{buffer[field].index==null ? 'Add' : 'Update'}} {{ field }}
                                                                </v-btn>
                                                                <v-btn @click="importList(field, Language.value, buffer[field].value, editing.treatment[field])">Import</v-btn>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col>
                                                        <v-btn @click="saveKtTreatment(editing.treatmentId, editing.ktSet.kt.treatments)">Save Treatment</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-col>                                                                
                                    </template>
                                </v-row>
                            </v-tab-item>
                        </v-tabs-items>

                    </v-container>    
                </v-card-text>

                <v-card-actions>
                    <v-btn @click="closeKtSet">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="saveKtSet(editing.ktSetId, editing.ktSet)">saveKtSet</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
  </div>
</template>

<script>
function clone(value){
    if(typeof value=='object'){
        let output = JSON.stringify(value)
        return JSON.parse(output)
    }
    if(typeof value=='string'){
        let output = JSON.stringify({value})
        return JSON.parse(output).value
    }
}
export default {
    async created(){
        this.ktSet = await this.getKtSet()
    },
    data(){
        return {
            ktSet: {},
            editing: {
                ktSet: null,
                recommendation: null,
                recommendationId: null,
                treatmentId: null,
                treatment: null,
                kt: null,
                ktSetId: null
            },
            buffer: {
                drugs: {index: null, value: null},
                start: {index: null, value: {}},
                monitoring: {index: null, value: {}},
                progression: {index: null, value: {}},
                qol: {index: null, value: {}},
                links: {index: null, value: {}},
                references: {index: null, value: {}},
                triggers: {index: null, value: null},
            },
            tab: "recommendations"
        }
    },
    methods: {
        copy(jsonData){
            let selBox = document.createElement('textarea');
            selBox.setAttribute("id", "json-copy");
            selBox.style.position = 'fixed';
            selBox.style.left = '0';
            selBox.style.top = '0';
            selBox.style.opacity = '0';
            selBox.value = JSON.stringify(jsonData);
            document.body.appendChild(selBox);
            selBox.focus();
            selBox.select();
            navigator.clipboard.writeText(selBox.value);
            document.body.removeChild(selBox);
        },
        fixRecords(Records){
            console.log('fixRecords',{Records})
            for(let ID in Records){
                let Record = Records[ID]
                if(typeof Record.triggers=='undefined'){
                    Record.triggers = []
                }
            }
        },
        write(key, object){
            this.cache.storage.local.set(key, object)
        },
        async getKtSet(){
            let self = this
            return new Promise((resolve)=>{
                self.busy = true
                setTimeout(() => {
                    let saved = self.cache.storage.local.get('ktSet')
                    resolve(saved ? saved : {})
                }, 100);
            })
        },
        languageTemplate(startingValue){
            let languages = this.Languages
            let output = {}
            for(let i=0; i<languages.length; i++){
                let language = languages[i]
                output[language.value] = startingValue ? startingValue : ""
            }
            return output
        },
        newKtSet(){
            let languageTemplate = this.languageTemplate

            function ktSetTemplate(){
                return {
                    recommendations: {},
                    kt: {
                        treatments: {},
                        links: {}
                    }
                }
            }

            let record = ktSetTemplate()

            this.editKtSet(null,record)
        },
        editKtSet(ktSetId, record){
            this.editing.ktSetId = ktSetId
            this.$set(this.editing, 'ktSet', clone(record))
        },
        saveKtSet(ktSetId, record){
            this.ktSet[ktSetId] = record
            this.write('ktSet', this.ktSet)
            if(confirm('Saved.  wanna close?')){
                this.closeKtSet()
            }
            this.$forceUpdate()
        },
        removeKtSet(ktSetId){
            if(confirm(`Remove ktSet: ${ktSetId}?`)){
                delete this.ktSet[ktSetId]
                this.write('ktSet', this.ktSet)
            }
        },
        closeKtSet(){
            this.editing.ktSet = null
        },
        newRecommendation(){
            let languageTemplate = this.languageTemplate
            function recommendationTemplate(){
                return {
                    body: languageTemplate(),
                    treatments: [],
                    footer: languageTemplate(),
                    triggers: []
                }
            }
            let record = recommendationTemplate()
            record.footer['en-ca'] = "For next steps please refer to the Locally Advanced/Metastatic tool card"

            this.editRecommendation(null, record)
        },
        editRecommendation(RecommendationId, record){
            this.editing.recommendationId = RecommendationId
            this.editing.recommendation = clone(record)
        },
        removeRecommendation(RecommendationId){
            if(confirm(`Remove recommendation: ${RecommendationId}?`)){
                delete this.editing.ktSet.recommendations[RecommendationId]
                this.saveKtSet(this.editing.ktSetId, this.editing.ktSet)
                this.$forceUpdate()
            }
            
        },
        newTreatment(){
            let languageTemplate = this.languageTemplate
            function treatmentTemplate(){
                return {
                    "option": languageTemplate(),
                    "recommendation": {
                        level: null,
                        strength: null
                    },
                    "kt_id": null
                }
            }
            let record = treatmentTemplate()
            this.editTreatment(record)
        },        
        editTreatment(record){
            this.editing.recommendation.treatments.push(clone(record))
        },
        removeTreatment(TreatmentIndex){
            this.editing.recommendation.treatments.splice(TreatmentIndex,1)
        },
        saveRecommendation(recommendationId, record){
            this.editing.ktSet.recommendations[recommendationId] = clone(record)
            this.ktSet[this.editing.ktSetId] = clone(this.editing.ktSet)
            this.write('ktSet', this.ktSet)
        },
        newKtTreatment(){
            let languageTemplate = this.languageTemplate
            function template(){
                return {
                    drugs: [],
                    title: languageTemplate(),
                    start: [],
                    monitoring: [],
                    progression: [],
                    qol: [],
                    links: [],
                    references: [],
                }
            }
            let record = template()
            this.editKtTreatment(null, record)
        },
        editKtTreatment(TreatmentId, Treatment){
            this.editing.treatmentId = TreatmentId
            this.editing.treatment = clone(Treatment)
        },
        removeKtTreatment(TreatmentId){

        },
        saveItem(field, index, value, target){
            if(typeof value=='object'){
                if(index==null){
                    target.push(clone(value))
                }else{
                    target[index] = clone(value)
                }
                for(let lang in this.buffer[field].value){
                    this.buffer[field].value[lang] = null
                }                
            }
            
            if(typeof value=='string'){
                if(index==null){
                    target.push(clone(value))
                }else{
                    target[index] = clone(value)
                }
                this.buffer[field].value = null
            }
            this.buffer[field].index = null

            
        },
        saveKtTreatment(treatmentId, target){
            target[treatmentId] = clone(this.editing.treatment)
            this.saveKtSet(this.editing.ktSetId, this.editing.ktSet)
        },
        editKtField(Field, Index, Target){
            this.buffer[Field].index = Index
            this.buffer[Field].value = clone(Target[Index])
            console.log({Field, Index, Target})
        },
        removeKtField(Field, Index, Target){
            if(confirm(`Remove ${Field} - ${Index}?`)){
                Target.splice(Index, 1)
            }
        },
        importList(Field, Language, Value, Target){
            let string = Value[Language]
            let array = string.split('\n')
            if(Target.length==0){
                let input = {}
                input[Language] = null
                console.log('importNewList',{Field, array, Target, Language})
                for(let i=0; i<array.length; i++){
                    input[Language] = array[i]
                    this.saveItem(Field, null, input, Target)
                }
            }else{
                console.log('importExistingList',{Field, array, Target, Language})
                let target = clone(Target)

                for(let i=0; i<array.length; i++){
                    let input = target[i]
                    input[Language] = array[i]
                    this.saveItem(Field, i, input, Target)
                }
            }
        }
    },
    computed:{
        editingUI(){
            return this.editing.ktSet ? true : false
        },
        Languages(){
            return this.$store.getters.controller.Language.languages
        }
    }
}
</script>

<style>

</style>